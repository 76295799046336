
<h1 class="h3 mb-3 fw-normal">Logga in</h1>
<app-text-input [label]="'Användarnamn'" [model]="username" (model)="username = $event"></app-text-input>
<app-text-input [label]="'Lösenord'" [model]="password" (model)="password = $event" (enterPressed)="signIn()" [type]="'password'"></app-text-input>
<app-error-message>{{errorMessage}}</app-error-message>
<div class="buttons">
  <button mat-button color="primary" (click)="navigateToRegister()">Registrera</button>
  <button mat-button color="primary" (click)="navigateToForgotPassword()">Glömt lösenord</button>
</div>
<button mat-raised-button color="primary" (click)="signIn()">Logga in</button>
