<mat-card>
  <mat-card-content>
    <app-item-search [items]="items" [label]="'Hitta mall med sak'" (itemSelected)="searchForItem($event)" (clearedInput)="showAllTemplates()" [clearAfterSelect]="false"></app-item-search>
    <mat-action-list>
      <button mat-list-item *ngFor="let key of templateKeys" (click)="selectTemplateGroup(key)" [class.selected]="key.description === selectedTemplateGroup?.description">{{key.description}}</button>
    </mat-action-list>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content>
    <app-filterable-item-list [disabled]="!selectedTemplateGroup" [selectedItems]="selectedTemplateGroup ? selectedTemplateGroup.items : []" (updateSelectStatus)="updateCheckStatus($event[1], $event[0])"></app-filterable-item-list>
  </mat-card-content>
</mat-card>
