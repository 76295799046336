<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <input type="text"
         placeholder="Sök"
         [attr.aria-label]="label"
         matInput
         [ngModel]="filterText"
         (ngModelChange)="filterText = $event"
         [matAutocomplete]="auto"
         (keydown.enter)="selectOrCreateItem()">
  <button matSuffix mat-icon-button aria-label="Clear" type="button" (click)="clearInput()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="addItem($event.option.value)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
